@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

/* Koachify Theme Color : #664fc8 */
/* Eventify Theme Color : #2676ea */

/* --------- TAILWIND CSS UTILITIES START ----------- */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* ---------  TAILWIND CSS UTILITIES END  ----------- */

// ------ icomoon settings
@font-face {
  font-family: icomoon;
  src: url('../fonts/icomoon/fonts/icomoon.ttf');
}

// ------- newly added css files
@import './tailwind.css';
@import './tailwind.output.css';
@import '~video-react/styles/scss/video-react';

// ------ root level var settings
:root {
  --theme-color: #2676ea;
  --primary-text-color: #18203a;
  --secondary-text-color: #4b5168;
  --danger-color: #d9534f;
  --theme-bg-color-hover: rgba(102, 78, 200, 0.11);
  --label-color: #18203a;
  --input-border-hover: 1px solid var(--theme-color);
  --input-text-color: #18203a;
  --input-placeholder-text-color: #4b5168;
  --input-border: 1px solid #e6edf3;
  --div-border: 1px solid #eaedf3;
  --offwhite-bg: #f5f7fa;
  --link-color: #468ffe;
}

body {
  font-family: Poppins;
  // font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  overflow: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// ------------ global
.loader-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95vh;
}
// .archive-list-max-height {
//   height: calc(100vh - 550px);
// }
.modal-max-height {
  height: calc(100vh - 64px - 64px - 72px - 32px - 32px - 36px - 62px);
}
.eventDetails-body-max-height,
.charity-body-max-height {
  height: calc(100vh - 220px);
}
.eventDetails-body-max-height,
.reels-body-max-height {
  height: calc(100vh - 112px);
}
// .charity-body-max-height {
//   height: calc(100vh - 60px - 56px);
// }
.icomoon {
  font-family: icomoon;
}

// ------------ hide scrollbar
.hide-scrollbar {
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

// ------------ login page section
.login-page {
  background: var(--theme-color);
  color: #fff;
}

// ------------ MainApp layout sectioon
.sidebar-menu-nav .active {
  color: var(--theme-color);
}

// ------------ arror multi directional with <i> starts
// --- type
.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}
.arrow-white {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}
// --- direction
.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
// ------------ arror multi directional with <i> ends

// ======= custom palates for dark theme reference start =======
.color {
  background-color: #0a0d14;
  background-color: #28364e;
  background-color: #556ff7;
  background-color: #fff;
  //
  background-color: #0a0a0b;
  background-color: #252939;
  background-color: #215bf0;
  background-color: #fff;
}
// =======  custom palates for dark theme reference end  =======


.pagination{
  @apply flex justify-center;
}
ul.pagination li.disabled {
  cursor:not-allowed
 
}
ul.pagination li.active {
  background-color: #00A2E9;
  color:#fff;
  border: 1px solid #0da2e2;
  border-radius: 2px;
  justify-content: center;
}
.pagination>li {
  clear: both;
  padding: 8px 15px;
}

